@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: "cera-pro-light";
  src: url("assets/fonts/CeraProLight.ttf");
}

@font-face {
  font-family: "cera-pro-regular";
  font-weight: 400 !important;
  src: url("assets/fonts/CeraProRegular.ttf");
}

@font-face {
  font-family: "cera-pro-medium";
  font-weight: 500 !important;
  src: url("assets/fonts/CeraProMedium.ttf");
}

@font-face {
  font-family: "cera-pro-bold";
  font-weight: 700 !important;
  src: url("assets/fonts/CeraProBold.ttf");
}

@font-face {
  font-family: "cera-pro-black";
  src: url("assets/fonts/CeraProBlack.ttf");
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}