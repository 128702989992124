.splash-enable-gradient-animation {
  animation: splash-enable-gradient-animation 2s;
}

@keyframes splash-enable-gradient-animation {
  0% {
    opacity: 0;
    transform: translateX(-500px) translateY(500px);
  }

  50% {
    opacity: 0;
    transform: translateX(-500px) translateY(500px);
  }

  100% {
    opacity: 1;
    transform: translate(0px);
  }
}

.splash-disable-gradient-animation {
  animation: splash-disable-gradient-animation 7s;
  opacity: 0;
}

@keyframes splash-disable-gradient-animation {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* .splash-disable-animation {
	animation: splash-disable-animation 0.3s;
}

@keyframes splash-disable-animation {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
} */

.bounceIn-animate {
  animation: bounceIn-animate 1s;
}

@keyframes bounceIn-animate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.dots-down-animation-one {
  animation: dots-down-animation-one 2s;
  opacity: 1;
  transform: translate(0px, 0px) scale(1);
}

@keyframes dots-down-animation-one {
  0% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  12% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  24% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1.1);
  }

  36% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  48% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  60% {
    opacity: 1;
    transform: translate(0px, 0px) scale(1);
  }

  72% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  84% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  96% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0px) scale(1);
  }
}

.dots-down-animation-two {
  animation: dots-down-animation-two 2s;
  opacity: 1;
  transform: translate(0px, 0px) scale(1);
}

@keyframes dots-down-animation-two {
  0% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  12% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  24% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  36% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1.1);
  }

  48% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  60% {
    opacity: 1;
    transform: translate(0px, 0px) scale(1);
  }

  72% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  84% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  96% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0px) scale(1);
  }
}

.dots-down-animation-three {
  animation: dots-down-animation-three 2s;
  opacity: 1;
  transform: translate(0px, 0px) scale(1);
}

@keyframes dots-down-animation-three {
  0% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  12% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  24% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  36% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  48% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1.1);
  }

  60% {
    opacity: 1;
    transform: translate(0px, 0px) scale(1);
  }

  72% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  84% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  96% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0px) scale(1);
  }
}

.dots-down-animation-four {
  animation: dots-down-animation-four 2s;
  opacity: 1;
  transform: translate(0px, 0px) scale(1);
}

@keyframes dots-down-animation-four {
  0% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  12% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  24% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  36% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  48% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  60% {
    opacity: 1;
    transform: translate(0px, 0px) scale(1.1);
  }

  72% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  84% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  96% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0px) scale(1);
  }
}

.dots-down-animation-five {
  animation: dots-down-animation-five 2s;
  opacity: 1;
  transform: translate(0px, 0px) scale(1);
}

@keyframes dots-down-animation-five {
  0% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  12% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  24% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  36% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  48% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  60% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  72% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1.1);
  }

  84% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  96% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0px) scale(1);
  }
}

.dots-down-animation-six {
  animation: dots-down-animation-six 2s;
  opacity: 1;
  transform: translate(0px, 0px) scale(1);
}

@keyframes dots-down-animation-six {
  0% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  12% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  24% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  36% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  48% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  60% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  72% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  84% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1.1);
  }

  96% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0px) scale(1);
  }
}

.dots-down-animation-seven {
  animation: dots-down-animation-seven 2s;
  opacity: 1;
  transform: translate(0px, 0px) scale(1);
}

@keyframes dots-down-animation-seven {
  0% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  12% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  24% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  36% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  48% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  60% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  72% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  84% {
    opacity: 0;
    transform: translate(-56px, -56px) scale(0);
  }

  96% {
    opacity: 1;
    transform: translate(-0px, -0px) scale(1.1);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0px) scale(1);
  }
}

.dots-move-active .dots-move-animation-one {
  animation: dots-move-animation-one 1.5s infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.dots-move-active .dots-move-animation-two {
  animation: dots-move-animation-two 1.5s infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.dots-move-active .dots-move-animation-three {
  animation: dots-move-animation-three 1.5s infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.dots-move-active .dots-move-animation-four {
  animation: dots-move-animation-four 1.5s infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.dots-move-active .dots-move-animation-five {
  animation: dots-move-animation-five 1.5s infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.dots-move-active .dots-move-animation-six {
  animation: dots-move-animation-six 1.5s infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes dots-move-animation-one {
  from {
    transform: translate(0px, 5px);
  }

  to {
    transform: translate(0px, 0px);
  }
}

@keyframes dots-move-animation-two {
  from {
    transform: translate(0px, -5px);
  }

  to {
    transform: translate(0px, 0px);
  }
}

@keyframes dots-move-animation-three {
  from {
    transform: translate(2px, -5px);
  }

  to {
    transform: translate(0px, 0px);
  }
}

@keyframes dots-move-animation-four {
  from {
    transform: translate(-5px, 2px);
  }

  to {
    transform: translate(0px, 0px);
  }
}

@keyframes dots-move-animation-five {
  from {
    transform: translate(5px, 0px);
  }

  to {
    transform: translate(0px, 0px);
  }
}

@keyframes dots-move-animation-six {
  from {
    transform: translate(0px, 5px);
  }

  to {
    transform: translate(0px, 0px);
  }
}

.logo-gradient-purple-pink {
  opacity: 1;
  background: linear-gradient(#a88cff 0%, #f3a1ff 86.39%, #ffa4ff 100%);
  height: 100%;
}

.logo-animate-gradient-purple-pink {
  animation: logo-animate-gradient-purple-pink 1s;
  opacity: 1;
  background: linear-gradient(#a88cff 0%, #f3a1ff 86.39%, #ffa4ff 100%);
  height: 100%;
}

.logo-gradient-green-blue {
  opacity: 0.7;
  background: linear-gradient(0deg, #00ffa0 0%, #008ed0 50%, #00ddff 100%);
  height: 100%;
}

.logo-animate-gradient-green-blue {
  animation: logo-animate-gradient-green-blue 1s;
  opacity: 0.7;
  background: linear-gradient(0deg, #00ffa0 0%, #008ed0 50%, #00ddff 100%);
  height: 100%;
}

.logo-animate-transparent {
  animation: logo-animate-transparent 1s;
  height: 100%;
}

.button-logo-animate {
  animation: button-logo-animate 1s;
  opacity: 1;
}

.single-button-logo-animate {
  animation: single-button-logo-animate 1s ease-out;
  opacity: 1;
}

@keyframes logo-animate-gradient-purple-pink {
  from {
    opacity: 0;
    height: 0;
  }

  to {
    opacity: 1;
    height: 100%;
  }
}

@keyframes logo-animate-gradient-green-blue {
  from {
    opacity: 0;
    height: 0;
  }

  to {
    opacity: 0.7;
    height: 100%;
  }
}

@keyframes logo-animate-transparent {
  from {
    height: 0;
  }

  to {
    height: 100%;
  }
}

@keyframes button-logo-animate {
  from {
    /* margin-top: -48px; */
    opacity: 0;
  }

  to {
    /* margin-top: 0px; */
    opacity: 1;
  }
}

@keyframes single-button-logo-animate {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1.02);
  }
}

.tagline-animate {
  animation: tagline-animate 800ms ease-out;
  opacity: 1;
  transform: scale(1);
}

@keyframes tagline-animate {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1.01);
  }
}

/* SPLASH LOGO ANIMATE */

/* mobile screen */
@media screen and (min-width: 375px) {
  .logo-title-animation {
    animation: reveal 3s;
    overflow: hidden;
    padding: 0px 0px;
  }

  .logo-title-animation h1 {
    animation: slide 3s;
    /* font-size: 48px; */
    height: 120px;
  }

  @keyframes slide {
    0% {
      margin-top: -30px;
    }

    25% {
      margin-top: -30px;
    }

    50% {
      margin-top: 0px;
    }

    75% {
      margin-top: 0px;
    }

    100% {
      margin-top: 0px;
    }
  }

  @keyframes reveal {
    0% {
      opacity: 0;
      height: 0px;
    }

    25% {
      opacity: 0;
      height: 0px;
    }

    50% {
      opacity: 1;
      height: 120px;
    }

    75% {
      opacity: 1;
      height: 120px;
    }

    100% {
      opacity: 1;
      height: 120px;
    }
  }
}

/* large mobile screen */
@media screen and (min-width: 640px) {
  .logo-title-animation {
    animation: reveal 3s;
    overflow: hidden;
    padding: 0px 0px;
  }

  .logo-title-animation h1 {
    animation: slide 3s;
    /* font-size: 48px; */
    height: 200px;
  }

  @keyframes slide {
    0% {
      margin-top: -30px;
    }

    25% {
      margin-top: -30px;
    }

    50% {
      margin-top: 0px;
    }

    75% {
      margin-top: 0px;
    }

    100% {
      margin-top: 0px;
    }
  }

  @keyframes reveal {
    0% {
      opacity: 0;
      height: 0px;
    }

    25% {
      opacity: 0;
      height: 0px;
    }

    50% {
      opacity: 1;
      height: 200px;
    }

    75% {
      opacity: 1;
      height: 200px;
    }

    100% {
      opacity: 1;
      height: 200px;
    }
  }
}

/* mini tablet screen */
@media screen and (min-width: 768px) {
  .logo-title-animation {
    animation: reveal 3s;
    overflow: hidden;
    padding: 10px 0px;
  }

  .logo-title-animation h1 {
    animation: slide 3s;
    width: 480px;
    /* font-size: 86px; */
    margin-right: -10px;
    height: auto;
  }

  @keyframes slide {
    0% {
      margin-left: -480px;
    }

    25% {
      margin-left: -480px;
    }

    50% {
      margin-left: 0px;
    }

    75% {
      margin-left: 0px;
    }

    100% {
      margin-left: 0px;
    }
  }

  @keyframes reveal {
    0% {
      opacity: 0;
      width: 0px;
    }

    25% {
      opacity: 0;
      width: 0px;
    }

    50% {
      opacity: 1;
      width: 480px;
      margin-right: -10px;
    }

    75% {
      opacity: 1;
      width: 480px;
      margin-right: -10px;
    }

    100% {
      opacity: 1;
      margin-right: -10px;
      width: 480px;
    }
  }
}

/* tablet screen */
@media screen and (min-width: 1024px) {
  .logo-title-animation {
    animation: reveal 3s;
    overflow: hidden;
    padding: 10px 0px;
  }

  .logo-title-animation h1 {
    animation: slide 3s;
    width: 630px;
    /* font-size: 110px; */
    margin-right: -10px;
    height: auto;
  }

  @keyframes slide {
    0% {
      margin-left: -630px;
    }

    25% {
      margin-left: -630px;
    }

    50% {
      margin-left: 0px;
    }

    75% {
      margin-left: 0px;
    }

    100% {
      margin-left: 0px;
    }
  }

  @keyframes reveal {
    0% {
      opacity: 0;
      width: 0px;
    }

    25% {
      opacity: 0;
      width: 0px;
    }

    50% {
      opacity: 1;
      width: 630px;
      margin-right: -10px;
    }

    75% {
      opacity: 1;
      width: 630px;
      margin-right: -10px;
    }

    100% {
      opacity: 1;
      margin-right: -10px;
      width: 630px;
    }
  }
}

/* desktop screen */
@media screen and (min-width: 1280px) {
  .logo-title-animation {
    animation: reveal 3s;
    overflow: hidden;
    padding: 10px 0px;
  }

  .logo-title-animation h1 {
    animation: slide 3s;
    width: 750px;
    /* font-size: 140px; */
    margin-right: -10px;
    height: auto;
  }

  @keyframes slide {
    0% {
      margin-left: -750px;
    }

    25% {
      margin-left: -750px;
    }

    50% {
      margin-left: 0px;
    }

    75% {
      margin-left: 0px;
    }

    100% {
      margin-left: 0px;
    }
  }

  @keyframes reveal {
    0% {
      opacity: 0;
      width: 0px;
    }

    25% {
      opacity: 0;
      width: 0px;
    }

    50% {
      opacity: 1;
      width: 750px;
      margin-right: -10px;
    }

    75% {
      opacity: 1;
      width: 750px;
      margin-right: -10px;
    }

    100% {
      opacity: 1;
      margin-right: -10px;
      width: 750px;
    }
  }
}

/* AVA ANIMATE */
/* mobile screen */
@media screen and (min-width: 0px) {
  .desktop-active-ava-animate,
  .desktop-not-active-ava-animate,
  .tablet-active-ava-animate,
  .tablet-not-active-ava-animate,
  .mini-tablet-active-ava-animate,
  .mini-tablet-not-active-ava-animate {
    opacity: 0;
    display: none;
  }

  .mobile-active-ava-animate {
    animation: mobile-active-ava-animate 0.7s ease;
    opacity: 1;
    display: block;
    transform: translateY(0px);
  }

  @keyframes mobile-active-ava-animate {
    from {
      opacity: 0;
      transform: translateY(-200px);
      display: none;
    }

    to {
      opacity: 1;
      transform: translateY(0px);
      display: block;
    }
  }

  .mobile-not-active-ava-animate {
    animation: mobile-not-active-ava-animate 0.7s ease;
    opacity: 0;
    display: none;
  }

  @keyframes mobile-not-active-ava-animate {
    0% {
      opacity: 1;
      transform: translateY(0px);
      display: block;
    }

    100% {
      opacity: 0;
      transform: translateY(-200px);
      display: none;
    }
  }
}

/* mini tablet screen */
@media screen and (min-width: 640px) {
  .desktop-active-ava-animate,
  .desktop-not-active-ava-animate,
  .tablet-active-ava-animate,
  .tablet-not-active-ava-animate,
  .mobile-active-ava-animate,
  .mobile-not-active-ava-animate {
    opacity: 0;
    display: none;
  }

  .mini-tablet-active-ava-animate {
    animation: mini-tablet-active-ava-animate 0.7s ease;
    opacity: 1;
    display: block;
    transform: translateY(0px);
  }

  @keyframes mini-tablet-active-ava-animate {
    from {
      opacity: 0;
      transform: translateY(-200px);
      display: none;
    }

    to {
      opacity: 1;
      transform: translateY(0px);
      display: block;
    }
  }

  .mini-tablet-not-active-ava-animate {
    animation: mini-tablet-not-active-ava-animate 0.7s ease;
    opacity: 0;
    display: none;
  }

  @keyframes mini-tablet-not-active-ava-animate {
    0% {
      opacity: 1;
      transform: translateY(0px);
      display: none;
    }

    100% {
      opacity: 0;
      transform: translateY(-200px);
      display: block;
    }
  }
}

/* tablet screen */
@media screen and (min-width: 1024px) {
  .desktop-active-ava-animate,
  .desktop-not-active-ava-animate,
  .mini-tablet-active-ava-animate,
  .mini-tablet-not-active-ava-animate,
  .mobile-active-ava-animate,
  .mobile-not-active-ava-animate {
    opacity: 0;
    display: none;
  }

  .tablet-active-ava-animate {
    animation: tablet-active-ava-animate 0.7s ease;
    opacity: 1;
    display: block;
    transform: translateY(0px);
  }

  @keyframes tablet-active-ava-animate {
    from {
      opacity: 0;
      transform: translateY(-200px);
      display: none;
    }

    to {
      opacity: 1;
      transform: translateY(0px);
      display: block;
    }
  }

  .tablet-not-active-ava-animate {
    animation: tablet-not-active-ava-animate 0.7s ease;
    opacity: 0;
    display: none;
  }

  @keyframes tablet-not-active-ava-animate {
    0% {
      opacity: 1;
      transform: translateY(0px);
      display: block;
    }

    100% {
      opacity: 0;
      transform: translateY(-200px);
      display: none;
    }
  }
}

/* desktop screen */
@media screen and (min-width: 1280px) {
  .tablet-active-ava-animate,
  .tablet-not-active-ava-animate,
  .mini-tablet-active-ava-animate,
  .mini-tablet-not-active-ava-animate,
  .mobile-active-ava-animate,
  .mobile-not-active-ava-animate {
    opacity: 0;
    display: none;
  }

  .desktop-active-ava-animate {
    animation: desktop-active-ava-animate 0.7s ease;
    opacity: 1;
    display: block;
    transform: translateY(0px);
  }

  @keyframes desktop-active-ava-animate {
    from {
      opacity: 0;
      transform: translateY(-200px);
      display: none;
    }

    to {
      opacity: 1;
      transform: translateY(0px);
      display: block;
    }
  }

  .desktop-not-active-ava-animate {
    animation: desktop-not-active-ava-animate 0.7s ease;
    opacity: 0;
    display: none;
  }

  @keyframes desktop-not-active-ava-animate {
    0% {
      opacity: 1;
      transform: translateY(0px);
      display: block;
    }

    100% {
      opacity: 0;
      transform: translateY(-200px);
      display: none;
    }
  }
}

.step-width-animate {
  animation: step-width-animate 0.8s ease;
}

@keyframes step-width-animate {
  from {
    width: 0%;
  }
  to {
    width: 100%;
    /* width: 91.666667%; */
  }
}

.step-height-animate {
  animation: step-height-animate 0.8s ease;
}

@keyframes step-height-animate {
  from {
    height: 0%;
  }
  to {
    height: 100%;
    /* height: 50%; */
  }
}

.show-benefit-animate {
  animation: show-benefit-animate 0.5s ease;
}

@keyframes show-benefit-animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.button-scroll-animate {
  animation: button-scroll-animate 1.5s ease-in-out infinite;
}

@keyframes button-scroll-animate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}

#swiperPartnerSay .swiper-wrapper {
  @apply flex items-center;
}

.partnerSayVideoPlayer video::-webkit-media-controls-panel{
  @apply !relative lg:!bottom-[5.5%] xl:!bottom-[7%];
}